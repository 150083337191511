<template>
  <div class="wrapper">
    <router-link class="close" :to="{ name: 'index' }"/>
    <div class="modal"
         v-if="!success && !checkEmail">
      <div id="mainImage" class="image"></div>
      <div class="title">
        How can we help you?
      </div>
      <div class="subtitle">
        Please select a topic below related to your inquiry. If you don't find what you need, fill out contact form.
      </div>
      <form class="form">
        <div class="block">
          <div class="field">
            <span class="field-title">Topic</span>
            <select :class="['select', {error: data.subject.error}]"
                    v-model="data.subject.value"
                    @focus="data.subject.error = false"
                    required>
              <option value disabled selected>Choose a topic</option>
              <option v-for="subject in subjects">{{ subject }}</option>
            </select>
          </div>
          <div v-if="data.subject.value === unsubscribe" class="description">
            <div class="title">
              You can cancel the subscription:
            </div>
            <div class="text">
              1. Open App Store app<br/>
              2. Tap on your icon at the top right corner<br/>
              3. Tap Subscription and select the App.<br/>
              4. Turn off the Automatic renewal option, then tap Done to confirm.<br/>
            </div>
          </div>
          <div v-if="data.subject.value === refund" class="description">
            <div class="title">
              Refund:
            </div>
            <div class="text">
              We cannot issue refunds, as developers do not receive direct funds from users. To refunds, you need to use
              the Apple service.
              Refund: https://support.apple.com/en-us/
            </div>
          </div>
          <div v-if="data.subject.value === unsubscribe || data.subject.value === refund"
               class="button-block">
            <router-link class="button" :to="{ name: 'index' }">
              OK
            </router-link>
          </div>
          <div v-if="data.subject.value && data.subject.value !== unsubscribe && data.subject.value !== refund"
               class="field">
            <span class="field-title">Application</span>
            <select :class="['select', {error: data.application.error}]"
                    v-model="data.application.value"
                    @focus="data.application.error = false"
                    required>
              <option value disabled selected>Choose application</option>
              <option v-for="app in applications">{{ app.name }}</option>
            </select>
          </div>
          <div v-if="data.subject.value && data.subject.value !== unsubscribe && data.subject.value !== refund"
               class="field">
            <span class="field-title">Your name</span>
            <input :class="['input', {error: data.name.error}]"
                   placeholder="Enter your name"
                   v-model="data.name.value"
                   @focus="data.name.error = false">
          </div>
        </div>
        <div class="block">
          <div v-if="data.subject.value && data.subject.value !== unsubscribe && data.subject.value !== refund"
               class="field">
            <span class="field-title">E-mail</span>
            <input :class="['input', {error: data.email.error}]"
                   placeholder="Enter your email"
                   v-model="data.email.value"
                   @focus="data.email.error = false">
          </div>
          <div v-if="data.subject.value && data.subject.value !== unsubscribe && data.subject.value !== refund"
               class="field">
            <span class="field-title">Message</span>
            <textarea :class="['textarea', {error: data.text.error}]"
                      placeholder="Enter your message"
                      v-model="data.text.value"
                      @focus="data.text.error = false"></textarea>
          </div>
          <div v-if="data.subject.value && data.subject.value !== unsubscribe && data.subject.value !== refund"
               class="button-block">
            <div class="button"
                 @click="feedback">
              Send
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="modal checkEmail"
         v-if="checkEmail">
      <div>
        <div class="title">Wait just a little bit more</div>
        <div class="subtitle">A message will be sent to the support team. We will reply you to this email:
        </div>
      </div>
      <div class="field">
        <input :class="['input', {error: data.email.error}]"
               placeholder="Enter your email"
               v-model="data.email.value"
               @focus="data.email.error = false">
      </div>
      <div class="image-saly-check"></div>
      <div class="button-block text-center">
        <div class="button"
             @click="send('yes')">
          Okey
        </div>
        <div class="link"
             @click="send('no')">
          No answer needed
        </div>
      </div>
    </div>

    <div class="modal result"
         v-if="success">
      <div>
        <div id="resultTitle" class="title">Your message was sent successfully</div>
        <div id="resultText" class="subtitle">We'll be in touch you soon</div>
      </div>
      <div class="image"></div>
      <div class="button-block">
        <router-link class="button" :to="{ name: 'index' }">
          OK
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import applications from '../../json/applications.json'

export default {
  name: "feedback",
  data: function () {
    return {
      checkEmail: false,
      success: false,
      applications: applications,
      subjects: [
        'Report an issue',
        'Other',
        'Offer cooperation',
        'Сancel the subscription',
        'Refund'
      ],
      unsubscribe: 'Сancel the subscription',
      refund: 'Refund',
      data: {
        subject: {
          type: 'text',
          value: '',
          error: ''
        },
        name: {
          type: 'text',
          value: '',
          error: ''
        },
        email: {
          type: 'email',
          value: '',
          error: ''
        },
        application: {
          type: 'text',
          value: '',
          error: ''
        },
        text: {
          type: 'text',
          value: '',
          error: ''
        },
      }
    }
  },
  watch: {
    'data.subject.value': {
      handler: function (val) {
        if (val !== '' && val !== this.unsubscribe && val !== this.refund) {
          $('#mainImage').addClass('lol');
          $('.modal').removeClass('margin');
        } else {
          $('#mainImage').removeClass('lol');
          $('.modal').addClass('margin');
        }
      },
      deep: true
    },
  },
  methods: {
    validate: function () {
      let send = true;
      for (const key of Object.keys(this.data)) {
        if (!this.data[key].value) {
          this.data[key].error = true;
          send = false;
        } else if (this.data[key].type === 'email' && !this.validEmail(this.data[key].value)) {
          this.data[key].error = true;
          send = false;
        }
      }
      return send;
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    feedback: function () {
      if (this.validate()) {
        this.checkEmail = true;
      }
    },
    send: function (answerNeeded) {
      if (this.validate()) {
        const formData = new FormData();
        let messageResult = "Subject: " + this.data.subject.value + "\n"
            + "Application: " + this.data.application.value + "\n"
            + "Answer needed: " + answerNeeded + "\n"
            + this.data.text.value;

        formData.append('email', this.data.email.value);
        formData.append('name', this.data.name.value);
        formData.append('subject', 'Message from ' + this.data.name.value);
        formData.append('message', messageResult);

        fetch(process.env.VUE_APP_SENDMAIL_URL, {
          method: 'POST',
          body: formData,
        })
            .then((response) => response.json())
            .then(result => {
              console.log(result)

              this.checkEmail = false;
              this.success = true;
            });
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import "style.less";
</style>